
$theme-colors: (
    //"info": tomato,
    //"danger": teal,
	//"primary": #BADA55
);

$carousel-control-color: #7b277d;
$carousel-caption-color: #7b277d;


@import "./scss/bootstrap";

:export {
	blue: $blue;
	indigo: $indigo;
	purple: $purple;
	pink: $pink;
	red: $red;
	orange: $orange;
	yellow: $yellow;
	green: $green;
	teal: $teal;
	cyan: $cyan;
	gray-200: $gray-200;
}

/*
.App {
  padding: 170px 0;
  text-align: center;
}

.Main {
  padding: 0px 0;
  text-align: center;
}

.Navibar-text {
	font-size: .6rem;
	margin-bottom: 0;
	font-weight: $font-weight-lighter;
	margin-bottom: 0;
}

.Navibar-user {
	font-size: 1rem;
	font-weight: $font-weight-bold;
	margin-bottom: 0;
}

.form-row {
	padding: 1px;
  /*display: flex;*/
  /*justify-content: space-around;*/
  /*align-items: center;*/
  /*flex-direction: column;*/
  /*height: 100vh;*/
/*}
*/


svg {
    overflow: visible !important;
}

.chart {
	width: 50vw;
	position: relative;
}

.srlogo {
	border-radius: 50%;
	height: 100%;
	max-height: 35px;
}

.legend-logo {
	height:15px;
	width:15px;
}

.legendtext {
	font-size: 14px;
}

/*
.overlaybtn2 {
position: relative;
  float: left;
}
*/

/* The animation code */
/*@keyframes slideopen {
  0%   {max-height: 0%;}
  100% {max-height: 100%;}
}
*/
/*
.collapsed p
{
    margin-bottom:-12em;
}
*/

tr.annotsettingcol div {
    max-height: 0px;
	transition:1s ease-in-out;
	overflow:hidden;
	padding: 0px;
}

td.annotsettingcol  {
	padding: 0px;
}


tr.annotsettingexp div {
	max-height: 250px;
	transition:1s ease-in-out;
}


.annot {
    height: calc(.8em + .375rem + 2px) !important;
    padding: .05rem .05rem !important;
    font-size: .75rem !important;
    line-height: 1.5;
    border-radius: .05rem;
}

.annottd {
    padding: 0 !important;	
    font-size: .05rem !important;	
}

.overview {
	padding: 0;	
	margin-bottom: 0;
	font-weight: bold;
}

.notowned {
	@extend .overview;	
	background-color: #F8F9FA;  /*light grey*/
}

.notownedselected {
	@extend .overview;	
	color: $white;
	background-color: #2A333C;
}

.owned {
	@extend .overview;	
	background-color: #FFC099;  /*light orange*/
}

.ownedselected {
	@extend .overview;	
	color: $white;
	background-color: #8F3700;
}

.hidden {
	max-height: 0px;
}


.stats {
	width: 20%;
	text-align: right;
}

.lstats {
	text-align: left;
}

.pos {
	@extend .stats;
	color: $green;
}

.neg {
	@extend .stats;
	color: $red;
}

.secur {
	font-weight: normal;
	text-align: left;
}

.hidebox {
	width: 5%;
	display: none;
}

.hideboxt {
	width: 5%;
}

tr.bold {
	font-weight: bold;
}